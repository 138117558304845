// TODO: have a talk with frontend team which tool to use to generate queries
//       I just used https://transform.tools/graphql-to-typescript to generate
//       the types below from the schema definition of the backend
//       but there are also more sophisticated solutions

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum EnvironmentState {
  Pending = "pending",
  Running = "running",
  ShuttingDown = "shuttingDown",
  Stopping = "stopping",
  Stopped = "stopped"
}

export enum EnvironmentStatus {
  Ok = "ok",
  Impaired = "impaired",
  InsufficientData = "insufficientData",
  NotApplicable = "notApplicable",
  Initializing = "initializing"
}

export enum EnvironmentStatusUpdate {
  Start = "start",
  Stop = "stop",
  Delete = "delete"
}

export enum EnvironmentProtocol {
  Rdp = "RDP",
  Vnc = "VNC"
}

export enum DiffState {
  Added = "ADDED",
  Deleted = "DELETED",
  Modified = "MODIFIED"
}

export enum DiffLocation {
  Ie = "IE",
  Phil = "PHIL"
}

export enum QualityCheckStatus {
  Qced = "QCED",
  QcReady = "QC_READY",
  QcFailed = "QC_FAILED"
}

export enum ActionFileType {
  Input = "Input",
  Output = "Output",
  Describing = "Describing",
  Script = "Script",
  Delete = "Delete"
}

export enum ActionType {
  Upload = "UPLOAD",
  AdfCreation = "ADF_CREATION",
  PlotCreation = "PLOT_CREATION",
  ReportCreation = "REPORT_CREATION",
  ModelExecution = "MODEL_EXECUTION",
  OtherAction = "OTHER_ACTION"
}

export type ListEnvironmentsInput = {
  activityId?: Maybe<Scalars["ID"]>;
};

export type CreateEnvironmentInput = {
  activityId: Scalars["ID"];
  imageId: Scalars["String"];
  instanceTypeId: Scalars["String"];
  environmentName?: Maybe<Scalars["String"]>;
};

export type EnvironmentSettingsInput = {
  environmentId: Scalars["ID"];
  instanceTypeId: Scalars["String"];
};

export type EnvironmentStatusInput = {
  environmentId: Scalars["ID"];
  environmentStatus: EnvironmentStatusUpdate;
};

export type ConnectEnvironmentInput = {
  environmentId: Scalars["ID"];
};

export type ImportFilesInput = {
  environmentId: Scalars["ID"];
  paths?: Maybe<Array<Scalars["String"]>>;
};

export type ListDiffsInput = {
  environmentId: Scalars["ID"];
};

export type ExportFilesInput = {
  environmentId: Scalars["ID"];
  exportPaths?: Maybe<Array<ActionFilePathInputType>>;
  additionalFiles?: Maybe<Array<ActionFileInputType>>;
  deletePaths?: Maybe<Array<Scalars["String"]>>;
  qualityCheckStatus?: Maybe<QualityCheckStatus>;
  description?: Maybe<Scalars["String"]>;
  actionType?: Maybe<ActionType>;
};

export type RepoObjectDiff = {
  repoId: Scalars["ID"];
  repoName: Scalars["String"];
  path: Scalars["String"];
};

export type CompareRepoObjectsInput = {
  environmentId: Scalars["ID"];
  repoObject: RepoObjectDiff;
  sourceLocation: DiffLocation;
};

export type ActionFilePathInputType = {
  actionFileType: ActionFileType;
  path: Scalars["String"];
};

export type ActionFileInputType = {
  actionFileType: ActionFileType;
  versionId: Scalars["ID"];
};

export type Environment = {
  __typename?: "Environment";
  environmentId: Scalars["ID"];
  environmentName?: Maybe<Scalars["String"]>;
  activityId: Scalars["ID"];
  state: EnvironmentState;
  instanceStatus?: Maybe<EnvironmentStatus>;
  systemStatus?: Maybe<EnvironmentStatus>;
  createdBy: Scalars["String"];
  createdAt: Scalars["String"];
  image: Image;
  instanceType: InstanceType;
  platformDetails: Scalars["String"];
  username: Scalars["String"];
  password: Scalars["String"];
};

export type Image = {
  __typename?: "Image";
  imageId: Scalars["ID"];
  imageName: Scalars["String"];
  platformDetails: Scalars["String"];
  architecture: Scalars["String"];
  createdAt: Scalars["String"];
  software: Array<Software>;
};

export type Software = {
  __typename?: "Software";
  name: Scalars["String"];
  version: Scalars["String"];
};

export type InstanceType = {
  __typename?: "InstanceType";
  instanceTypeId: Scalars["String"];
  cpu: Scalars["Int"];
  memory: Scalars["Int"];
  architectures: Array<Scalars["String"]>;
};

export type FileDiffOutput = {
  __typename?: "FileDiffOutput";
  state: DiffState;
  isConflict: Scalars["Boolean"];
  path: Scalars["String"];
  location?: Maybe<DiffLocation>;
  lastUpdated: Scalars["String"];
};

export type RepoFile = {
  __typename?: "RepoFile";
  repoId: Scalars["ID"];
  repoName: Scalars["String"];
  lastSync?: Maybe<Scalars["String"]>;
  files: Array<Maybe<FileDiffOutput>>;
};

export type ListDiffOutput = {
  __typename?: "ListDiffOutput";
  computing: Scalars["Boolean"];
  lastUpdated: Scalars["String"];
  repoFiles?: Maybe<Array<Maybe<RepoFile>>>;
};

export type InteractiveRepoObject = {
  __typename?: "InteractiveRepoObject";
  repoId: Scalars["ID"];
  repoName: Scalars["String"];
  files: Array<Scalars["String"]>;
};
