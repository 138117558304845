import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";

export const LIST_ENVIRONMENT_TYPES = gql`
  query listInstanceTypes($limit: Int!, $cursor: String) {
    listInstanceTypes(input: { limit: $limit, cursor: $cursor }) {
      instanceTypes {
        instanceTypeId
        cpu
        memory
        architectures
      }
      nextCursor
    }
  }
`;

export function useListInstanceTypesQuery(limit: number = 50) {
  const {
    data: environmentTypes,
    loading: isLoadingEnvironmentTypes,
    error: errorListEnvironmentTypes,
    fetchMore: fetchMore
  } = useQuery(LIST_ENVIRONMENT_TYPES, {
    context: { clientName: "interactive" },
    variables: { limit }
  });

  const listMoreImages = (limit: number, nextCursor: string) =>
    fetchMore({
      variables: {
        limit: limit,
        cursor: nextCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        fetchMoreResult.listInstanceTypes.images = [
          ...(prevResult?.listInstanceTypes?.instanceTypes
            ? prevResult.listInstanceTypes.instanceTypes
            : []),
          ...fetchMoreResult.listInstanceTypes.instanceTypes
        ];
        return fetchMoreResult;
      }
    });

  useEffect(() => {
    if (environmentTypes && environmentTypes.listInstanceTypes.nextCursor) {
      listMoreImages(limit, environmentTypes.listInstanceTypes.nextCursor);
    }
  }, [environmentTypes]);

  return {
    environmentTypes: environmentTypes?.listInstanceTypes.instanceTypes || [],
    isLoadingEnvironmentTypes,
    errorListEnvironmentTypes
  };
}
