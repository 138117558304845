import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";

export const LIST_DIFFS = gql`
  query listDifferences($environmentId: ID!, $limit: Int!, $cursor: String) {
    listDifferences(
      input: { environmentId: $environmentId, limit: $limit, cursor: $cursor }
    ) {
      differences {
        key
        type
        location
        conflict
        modifiedAt
      }
      diffId
      startedAt
      stoppedAt
      nextCursor
    }
  }
`;

export function useListDiffsQuery(
  environmentId: string,
  polling: boolean = false,
  limit: number = 100
) {
  const {
    data,
    loading: isLoadingListDiffs,
    error: errorListDiffs,
    fetchMore: fetchMore,
    refetch: refetchDiff
  } = useQuery(LIST_DIFFS, {
    context: { clientName: "interactive" },
    notifyOnNetworkStatusChange: true,
    variables: { environmentId: environmentId, limit: limit },
    fetchPolicy: "no-cache",
    pollInterval: polling ? 15000 : 0 // Query data periodically every 15 sec to query the list difference table
  });

  const listMore = (nextCursor: string) =>
    fetchMore({
      variables: {
        cursor: nextCursor
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        let currentDiff = prevResult?.listDifferences?.diferences || [];
        let newDiff = data?.listDifferences.differences || [];

        fetchMoreResult.listDifferences = {
          ...data?.listDifferences,
          diferences: [...currentDiff, ...newDiff]
        };

        return fetchMoreResult;
      }
    });

  useEffect(() => {
    if (data && data?.listDifferences.nextCursor) {
      listMore(data?.listDifferences.nextCursor);
    }
  }, [data]);

  return {
    refetchDiff,
    diffs: data?.listDifferences || {
      differences: [],
      diffId: null,
      startedAt: null,
      stoppedAt: null
    },
    isLoadingListDiffs,
    errorListDiffs
  };
}
