import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import NavBarPmxActivity from "../pmxActivity/navbarPmxActivity";
import { CreateJob } from "../abstractComponents/createJob";
import { useQueryEnvironmentsQuery } from "../../backend/hooks/interactive/queryListEnvironments";
import { useQueryListInteractiveFilesQuery } from "../../backend/hooks/interactive/queryListInteractiveFiles";
import { InteractiveRepoObject } from "../../backend/interactiveTypes";
import getTreeHierarchyFromRepoObjectList from "../../helpers/treeHelper";
import { GetRepoObjectQueryData, RepoObject } from "../../backend/types";
import { ApolloError } from "@apollo/client/errors";
export function InteractiveCreateJob() {
  const { activityId, activityData } = useContext(PmxActivityDetailsContext);
  const environmentId = (useParams() as { environmentId: string })
    .environmentId;

  const { environments, isLoadingEnvironments, errorListEnvironments } =
    useQueryEnvironmentsQuery(activityId, false, environmentId);
  const {
    getListIntreactiveFiles,
    interactiveFiles,
    isLoadingInteractiveFiles,
    errorLoadingInteractiveFiles
  } = useQueryListInteractiveFilesQuery();

  const castingFilesToRepObjectTree = () => {
    let updateObject: GetRepoObjectQueryData[] = [];
    interactiveFiles?.forEach((repo: InteractiveRepoObject) => {
      let castFiles: RepoObject[] = [];
      repo.files?.forEach((file) => {
        castFiles.push({
          name: file,
          isValid: false,
          isDir: file.endsWith("/") ? true : false
        });
      });
      updateObject.push({
        repo: {
          name: repo.repoName,
          id: repo.repoId
        },
        repoObjects: castFiles,
        isReadOnlyRepository: false
      } as unknown as GetRepoObjectQueryData);
    });

    return getTreeHierarchyFromRepoObjectList(updateObject, true);
  };

  const treeSelection = {
    interactiveTreeSelection: castingFilesToRepObjectTree(),
    environmentId: environmentId,
    getFilesForSelection: getListIntreactiveFiles
  };

  return (
    <>
      <NavBarPmxActivity activeIndex={1} />

      <div className="app_content pmx_app_content">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="float-start pmx_bradcrumbs"
        >
          <Link to={`/activity/${activityId}`}>
            {activityData?.trialNumber}
          </Link>
          <Link to={`/activity/${activityId}/interactive`}>
            Interactive Environments
          </Link>
          <Link to={`/activity/${activityId}/interactive/${environmentId}`}>
            {environments[0].environmentName}
          </Link>
          <Typography>Create Execution Job</Typography>
        </Breadcrumbs>
        <br />
        <hr />
        <CreateJob
          parentLoading={[isLoadingInteractiveFiles, isLoadingEnvironments]}
          parentError={
            [
              errorLoadingInteractiveFiles,
              errorListEnvironments
            ] as ApolloError[]
          }
          treeSelection={treeSelection}
        />
      </div>
    </>
  );
}
